import React, { useEffect, useState } from 'react'
import './style.scss'
import { useMutation, useQuery } from '@apollo/client'
import { GetBuffer } from '../../graphql/queries'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import DateInput from '../../components/DateInput'
import Input from '../../components/Input'
import Textarea from '../../components/Textarea'
import { UpdateBufferItem } from '../../graphql/mutations'
import ActivityIndicator from '../../components/ActivityIndicator'
import { parseDate } from '../../util/format'
import Button from '../../components/Button'
import { useNotification } from '../../providers/Notification'
import { BufferUpdated } from '../../graphql/subscriptions'
import BufferModal from '../../components/buffer-modal'
import TechnicalCard from '../../components/TechnicalCard'
import Checkbox from '../../components/Checkbox'
import BoxWorkflow from '../../components/BoxWorkflow'
import MaterialDeliveryRows from '../../components/MaterialDeliveryRows'
import FactoryMaterialOrderRows from '../../components/FactoryMaterialOrderRows'
import DeliveryNotesList from '../../components/DeliveryNotesList'
import Printouts from '../../components/Printouts'

const BufferScreen = () => {

    const { t } = useTranslation()
    const { id } = useParams()
    const { dispatch } = useNotification()
    const navigate = useNavigate()

    const [prodData, setProdData] = useState(null)
    const [modifiedFields, setModifiedFields] = useState([])
    const [showBufferModal, setShowBufferModal] = useState(false)

    const { refetch, loading, subscribeToMore } = useQuery(GetBuffer, {
        variables: {
            id: parseInt(id)
        },
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            const { getBuffer } = data
            if (getBuffer) {
                setProdData(getBuffer)
            }
        },
    })

    useEffect(() => {
        const unsub = subscribeToMore({
            document: BufferUpdated,
            variables: {
                id: parseInt(id),
            },
            updateQuery: (prev, { subscriptionData }) => {
                const newData = subscriptionData?.data?.bufferUpdated
                if (!newData) return

                setProdData(newData)
            },
        })

        return () => unsub()
    }, [])

    const [updateBufferItem] = useMutation(UpdateBufferItem)

    const setField = (field, value) => {
        const finalValue = field === 'PRICE' ? parseFloat(value) : value
        setProdData({
            ...prodData,
            [field]: finalValue,
        })
        if (!modifiedFields.includes(field)) {
            setModifiedFields([...modifiedFields,field])
        }
    }

    const updateProperty = async () => {
        if (modifiedFields.length === 0) {
            return
        }
        const changedData = {}
            modifiedFields.forEach(field => {
            changedData[field] = prodData[field]
        })
        try {
            const result = await updateBufferItem({
                variables: {
                    id: parseInt(id),
                    data: changedData,
                },
            })
            if (result?.data?.updateBufferItem === 'Success') {
                setModifiedFields([])
            }
        } catch (err) {
            console.log('updateProperty:', err)
        }
    }

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (modifiedFields.length > 0) {
                event.preventDefault()
                event.returnValue = ''
            }
        }
    
        window.addEventListener('beforeunload', handleBeforeUnload)
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [modifiedFields])

    const handlePrevNext = async (direction) => {
        try {
            const rows = JSON.parse(localStorage.getItem('buffer'))
            const currentIndex = rows.findIndex(x => x.ID === prodData.ID)

            if (direction === 'prev' && currentIndex <= 0) return
            if (direction === 'next' && currentIndex >= rows.length - 1) return

            const id = direction === 'next' ? rows[currentIndex + 1]?.ID : rows[currentIndex - 1]?.ID
            navigate(`/buffer/${id}`)
        } catch (err) {
            console.log('HandlePrevNext', err)
        }
    }

    const handleIssueSuccess = ({ amount }) => {
        setShowBufferModal(false)
        refetch()

        dispatch({
            type: 'ADD',
            payload: {
                type: 'success',
                content: `${amount > 1 ? `${amount} toodet` : `Toode`} edukalt väljastatud`
            },
        })
    }

    const handleTogglePosition = () => {
        setField('SHIPPED', prodData?.SHIPPED ? null : new Date())
    }

    if (loading) return (
        <div className='loading-view'>
            <ActivityIndicator />
        </div>
    )

    return (
        <div className='buffer production'>
            <div className='inner'>
                <div className='inner-title'>
                    <h2>{t('Buffer andmed')}</h2>
                    <div className='inner-title--actions'>
                        <Button
                            label={t('<')}
                            onClick={() => handlePrevNext('prev')}
                        />
                        <Button
                            label={t('>')}
                            onClick={() => handlePrevNext('next')}
                        />
                        <Button
                            label={t('Väljasta')}
                            onClick={() => 
                                {
                                    updateProperty()
                                    setShowBufferModal(true)
                                }
                            }
                            // disabled={prodData?.SHIPPED || !prodData?.RKOGUS || prodData.RKOGUS < 1}
                        />
                        <Button
                            label={prodData?.SHIPPED ? t('Ava positsioon') : t('Sulge positsioon')}
                            onClick={() => 
                                {
                                    updateProperty()
                                    handleTogglePosition()
                                }
                            }
                        />
                        <TechnicalCard
                            orderRowId={prodData?.ORDER_ROW_ID}
                            productionCode={prodData?.PRODUCTION_CODE}
                            production={prodData}
                            isBuffer={true}
                            prodFetch={refetch}
                        />
                        <Button
                            label={t('Salvesta')}
                            onClick={() => updateProperty()}
                            disabled={modifiedFields.length === 0 || loading}
                            loading={loading}
                        />
                    </div>
                </div>
                {prodData ?
                    <div className='production-fields'>
                        <div className='editable-fields'>
                            <div className='field-row'>
                                <div className='field-col production-fields--info'>
                                    <Input
                                        label={t('Number')}
                                        type={'number'}
                                        value={prodData.ORDERNO}
                                        onChange={(e) => setField('ORDERNO', parseInt(e.target.value))}
                                    />
                                    <DateInput
                                        label={t('Tellimuse kuupäev')}
                                        value={prodData.ORDER_DATE && prodData.ORDER_DATE !== '0000-00-00' ? new Date(prodData.ORDER_DATE) : null}
                                        onChange={(val) => setField('ORDER_DATE', val)}
                                    />
                                    <DateInput
                                        label={t('Tähtaeg')}
                                        value={prodData.DEADLINE && prodData.DEADLINE !== '0000-00-00' ? new Date(prodData.DEADLINE) : null}
                                        onChange={(val) => setField('DEADLINE', val)}
                                    />
                                    <DateInput
                                        label={t('Reaalne tähtaeg')}
                                        value={prodData.NEW_DEADLINE && prodData.NEW_DEADLINE !== '0000-00-00' ? new Date(prodData.NEW_DEADLINE) : null}
                                        onChange={(val) => setField('NEW_DEADLINE', val)}
                                    />
                                    <DateInput
                                        label={t('Tootmise algus')}
                                        value={prodData.START_DATE && prodData.START_DATE !== '0000-00-00' ? new Date(prodData.START_DATE) : null}
                                        onChange={(val) => setField('START_DATE', val)}
                                    />
                                    <DateInput
                                        label={t('Tootmise lõpp')}
                                        value={prodData.FIN_DATE && prodData.FIN_DATE !== '0000-00-00' ? new Date(prodData.FIN_DATE) : null}
                                        onChange={(val) => setField('FIN_DATE', val)}
                                    />
                                    <Input
                                        label={t('Aluseid')}
                                        type={'text'}
                                        value={prodData.ALUSEID}
                                        onChange={(e) => setField('ALUSEID', e.target.value)}
                                    />
                                    <Input
                                        label={t('Tegelik kogus')}
                                        type={'number'}
                                        value={`${prodData?.RKOGUS || ''}`}
                                        onChange={(e) => setField('RKOGUS', parseInt(e.target.value))}
                                    />
                                </div>
                                <div className='field-col production-fields--comment'>
                                    <Textarea
                                        label={t('Kommentaar')}
                                        value={prodData.PROD_COMMENT}
                                        onChange={(e) => setField('PROD_COMMENT', e.target.value)}
                                    />
                                    <Checkbox
                                        label={t('Laomaterjal')}
                                        value={prodData.STOCK_MAT === 1}
                                        onChange={(e) => setField('STOCK_MAT', e.target.checked ? 1 : 0)}
                                    />
                                    <Checkbox
                                        label={t('Mat. laaditud')}
                                        value={prodData.MAT_LOADED === 1}
                                        onChange={(e) => setField('MAT_LOADED', e.target.checked ? 1 : 0)}
                                    />
                                    <Checkbox
                                        label={t('Offset')}
                                        value={prodData.OFSET === 1}
                                        onChange={(e) => setField('OFSET', e.target.checked ? 1 : 0)}
                                    />
                                    <Input
                                        className={'measurement-item'}
                                        label={t('Mõõt')}
                                        type={'text'}
                                        value={prodData.STOCK_MOOT}
                                        onChange={(e) => setField('STOCK_MOOT', e.target.value)}
                                    />
                                    <Checkbox
                                        label={t('Eksporditud')}
                                        value={prodData.EXPORTED === 1}
                                        onChange={(e) => setField('EXPORTED', e.target.checked ? 1 : 0)}
                                    />
                                </div>
                                <div className='field-col production-fields--tables'>
                                    <div className='production-fields--printout'>
                                        <Printouts
                                            buffer={prodData}
                                            onUpdateField={updateProperty}
                                        />
                                    </div>
                                    <div className='factory-materials'>
                                        <FactoryMaterialOrderRows
                                            bufferId={id}
                                        />
                                    </div>
                                    <div className='material-delivery'>
                                        <MaterialDeliveryRows
                                            bufferId={id}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='field-row box-row'>
                            <div className='field-col'>
                                <h5>{t('Karp')}</h5>
                                <div className='box-fields'>
                                    <Input
                                        label={t('Kogus')}
                                        value={prodData?.KOGUS}
                                        type={'number'}
                                        onChange={(e) => setField('KOGUS', parseInt(e.target.value))}
                                    />
                                    <Input
                                        label={t('Hind')}
                                        value={prodData.PRICE}
                                        type={'number'}
                                        onChange={(e) => setField('PRICE', e.target.value)}
                                    />
                                    <div className='box-fields-item'>
                                        <span className='box-fields-item--label'>{t('Tüüp')}</span>
                                        <span className='box-fields-item--value'>{prodData.BOX}</span>
                                    </div>
                                    <div className='box-fields-item'>
                                        <span className='box-fields-item--label'>{t('Trükk')}</span>
                                        <span className='box-fields-item--value'>{prodData.TRYKK}</span>
                                    </div>
                                    <div className='box-fields-item'>
                                        <span className='box-fields-item--label'>{t('Materjal')}</span>
                                        <span className='box-fields-item--value'>{prodData.MATERIAL}</span>
                                    </div>
                                    <div className='box-fields-item'>
                                        <span className='box-fields-item--label'>{t('FSC')}</span>
                                        <span className='box-fields-item--value'>{prodData.FSC}</span>
                                    </div>
                                    <div className='box-fields-item'>
                                        <span className='box-fields-item--label'>{t('Mõõt')}</span>
                                        <span className='box-fields-item--value'>{prodData.MOOT}</span>
                                    </div>
                                    <div className='box-fields-item'>
                                        <span className='box-fields-item--label'>{t('Kliendi tootekood')}</span>
                                        <span className='box-fields-item--value'>{prodData.KLIENT_BOXCODE}</span>
                                    </div>
                                    <div className='box-fields-item'>
                                        <span className='box-fields-item--label'>{t('Toote kood')}</span>
                                        <span className='box-fields-item--value'>{prodData.PRODUCTION_CODE}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='field-col'>
                                <div className='client-fields'>
                                    <h5>{t('Klient')}</h5>
                                    <div className='field-row'>
                                        <div className='field-col'>
                                            <div className='client-fields-item'>
                                                <span className='client-fields-item--label'>{`${t('Nimi')}:`}</span>
                                                <span className='client-fields-item--value'>{prodData.client && prodData.client.NAME}</span>
                                            </div>
                                            <div className='client-fields-item'>
                                                <span className='client-fields-item--label'>{`${t('Tellimuse number')}:`}</span>
                                                <span className='client-fields-item--value'>{prodData.KLIENT_ORDER}</span>
                                            </div>
                                        </div>
                                        <div className='field-col'>
                                            <div className='sales-fields-item'>
                                                <span className='sales-fields-item--label'>{t('Kommentaar')}</span>
                                                <span className='sales-fields-item--value'>{prodData.KOMMENTAAR}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className='empty-result'>
                        <p>{t('Production not found')}</p>
                    </div>
                }
            </div>
            <BufferModal
                show={showBufferModal}
                close={() => setShowBufferModal(false)}
                data={prodData}
                onSuccess={handleIssueSuccess}
            />
        </div>
    )
}

export default BufferScreen