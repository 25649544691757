const IsDev = process.env.NODE_ENV === 'development'

const Constants = {
    Failure: 2.0,
    PrintPrice: 0.019,
    LabourPlaceholder: 0,
    OfsetPrice: 0.192,
}

const Status = {
    All: 'all',
    InProduction: 'inProduction',
    Sent: 'sent',
    Ready: 'ready',
    PrepaymentNotDone: 'notPrepayed',
    Overdue: 'overdue',
    Pending: 'pending',
    Debt: 'debt',
    ReadyNotSent: 'readyNotSent',
    TransportOrdered: 'transport',
    MissingBlueprint: 'missingBlueprint',
}

const TransportOptions = [
    {
        value: 'Multipakend',
        label: 'Multipakend',
    },
    {
        value: 'Klient',
        label: 'Klient',
    },
    {
        value: 'Tellida',
        label: 'Tellida',
    },
]

const MachineCodes = [
    {
        label: 'SLITTER',
        value: 'SLITTER',
    },
    {
        label: 'TIGEL',
        value: 'TIGEL',
    },
    {
        label: 'SRE',
        value: 'SRE',
    },
    {
        label: 'INLINE',
        value: 'INLINE',
    },
    {
        label: 'SLOTTER',
        value: 'SLOTTER',
    },
    {
        label: 'KLIIM',
        value: 'KLIIM',
    },
    {
        label: 'ALIIM',
        value: 'ALIIM',
    },
    {
        label: '_3LIIM',
        value: '_3LIIM',
    },
    {
        label: 'LAMINAATOR',
        value: 'LAMINAATOR',
    },
    {
        label: 'TOOTLEMINE',
        value: 'TOOTLEMINE',
    },
    {
        label: 'EKSTSEN',
        value: 'EKSTSEN',
    },
    {
        label: 'AFD',
        value: 'AFD',
    },
    {
        label: 'FPR',
        value: 'FPR',
    },
    {
        label: 'PREMAID',
        value: 'PREMAID',
    },
    {
        label: 'TITAN',
        value: 'TITAN',
    },
    {
        label: 'VERONA',
        value: 'VERONA',
    },
]

const HaltReasons = [
    {
        label: 'Mehhaaniline rike',
        value: 'MECHANICAL_FAILURE',
        type: 'UNPLANNED',
    },
    {
        label: 'Elektriline rike',
        value: 'ELECTRICAL_FAILURE',
        type: 'UNPLANNED',
    },
    {
        label: 'Elektrooniline rike',
        value: 'ELECTRONICAL_FAILURE',
        type: 'UNPLANNED',
    },
    {
        label: 'Organisatoorne',
        value: 'ORGANIZATIONAL',
        type: 'UNPLANNED',
    },
]

const FileUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3050/files/' : process.env.REACT_APP_FILES

const Direction = {
    Up: 'up',
    Down: 'down',
    Left: 'left',
    Right: 'right',
}

const workflowOrderFields = [
    'SLITTER', 'TIGEL', 'SRE', 'INLINE', 'SLOTTER', 'KLIIM', 'ALIIM', '_3LIIM',
    'LAMINAATOR', 'TOOTLEMINE', 'EKSTSEN', 'AFD', 'FPR', 'PREMAID', 'TITAN', 'VERONA'
]

const workflowFields = [
    ...workflowOrderFields,
    'PCS_PER_HIT',
    'STOCK_MOOT',
    'PCS_PER_STOCK'
]

export {
    IsDev,
    Constants,
    Status,
    TransportOptions,
    MachineCodes,
    FileUrl,
    HaltReasons,
    Direction,
    workflowOrderFields,
    workflowFields
}