import { useMutation } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { UpdateBlueprint, CreateBlueprintLog, UpdateBufferItem, UpdateProductionItem, UploadFile, SendProductionToPlanning } from '../graphql/mutations'
import Button from './Button'
import FileInput from './FileInput'
import { useTranslation } from 'react-i18next'
import BoxWorkflow from './BoxWorkflow'
import BoxColorFields from './BoxColorFields'
import BoxNotification from './BoxNotification'
import Input from './Input'
import Textarea from './Textarea'
import { FileUrl, workflowFields, workflowOrderFields } from '../util/const'
import BlueprintModal from './BlueprintModal'
import BlueprintLog from './BlueprintLog'
import { GetBlueprintLog } from '../graphql/queries'
import Directions from './Directions'
import SelectInput from './SelectInput'
import AdditionalModal from './AdditionalModal'
import ConfirmModal from './ConfirmModal'
import { useNotification } from '../providers/Notification'

const initialBlueprint = {
    COMMENT: '',
    PCS_PER_HIT: '',
    PCS_PER_STOCK: '',
    SLITTER: '',
    FLEXO: '',
    TIGEL: '',
    SRE: '',
    INLINE: '',
    SLOTTER: '',
    KLIIM: '',
    ALIIM: '',
    _3LIIM: '',
    LAMINAATOR: '',
    STOCK_AMOUNT: '',
    STOCK_MOOT: '',
    OFFSET_AMOUNT: '',
    OFFSET_MOOT: '',
    TOOTLEMINE: '',
    EKSTSEN: '',
    PREMAID: '',
    PRODUCTION_CODE: '',
    KLISHEE_LOCATION: '',
    MATRIITS_LOCATION: '',
    VERONA: '',
    TITAN: '',
    AFD: '',
    FPR: '',
    KLISHEE: '',
    MATRIITS: '',
    TT: '',
    PANTONE1: '',
    PANTONE2: '',
    PANTONE3: '',
    PANTONE4: '',
    PACKAGING_METHOD: '',
    AMOUNT_PALLET: '',
    SLOTS: '',
    FILE: null,
    CONSTRUCTOR: '',
    DESIGNER: '',
    PALLET_TYPE: '',
    PALLET_LAYOUT: '',
}

const validateFields = (data, fileName) => {
    const blueprintData = {}
    const prodData = {}

    for (const [key, value] of Object.entries(data)) {
        switch (key) {
            case 'PRODUCTION_CODE':
            case '__typename':
            case 'ID':
            case 'CREATOR':
            case 'CREATED':
            case 'designer':
            case 'constructorUser':
                break
            case 'FILE': {
                if (!fileName) break

                blueprintData.FILE = fileName
                break
            }
            case 'PACKAGING_METHOD':
            case 'TT':
            case 'KLISHEE':
            case 'MATRIITS':
            case 'KLISHEE_LOCATION':
            case 'MATRIITS_LOCATION':
            case 'COMMENT':
            case 'OFFSET_MOOT':
            case 'STOCK_MOOT':
            case 'PANTONE1':
            case 'PANTONE2':
            case 'PANTONE3':
            case 'PANTONE4':
            case 'FLUTE':
            case 'PRINTING':
            case 'FEEDING':
            case 'NUUT':
            case 'PALLET_LAYOUT':
            case 'PALLET_TYPE':
                blueprintData[key] = value
                break
            case 'PCS_PER_STOCK':
            case 'PCS_PER_HIT':
                blueprintData[key] = parseFloat(value)
                break
            case 'STOCK_AMOUNT':
                prodData[key] = parseInt(value, 10)
                blueprintData[key] = parseInt(value, 10)
                break
            default:
                blueprintData[key] = parseInt(value, 10)
        }
    }

    return {
        blueprintData,
        prodData
    }
}

const EditBlueprint = ({
    blueprint,
    orderRowId,
    production,
    isBuffer,
    onSuccess,
    constructors,
    prodFetch,
    refetchBlueprint
}) => {
    const { t } = useTranslation()
    const { dispatch } = useNotification()
    const [updateBlueprint] = useMutation(UpdateBlueprint)
    const [createBlueprintLog] = useMutation(CreateBlueprintLog)
    const [sendProductionToPlanning] = useMutation(SendProductionToPlanning)
    const [updateProd] = useMutation(UpdateProductionItem)
    const [updateBuffer] = useMutation(UpdateBufferItem)
    const [data, setData] = useState(initialBlueprint)
    const [errors, setErrors] = useState(initialBlueprint)
    const [upload] = useMutation(UploadFile)
    const [loading, setLoading] = useState(false)
    const [loadingField, setLoadingField] = useState(null)

    const [changedFields, setChangedFields] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [showPlanningConfirmationModal, setShowPlanningConfirmationModal] = useState(false)
    const [isWorkflowProductBased, setIsWorkflowProductBased] = useState(false)

    useEffect(() => {
        const blueprintData = { ...blueprint }

        if (production?.materialOrders?.length && !blueprint?.STOCK_MOOT) {
            blueprintData.STOCK_MOOT = production.materialOrders[0].SIZE
            blueprintData.STOCK_AMOUNT = production.materialOrders[0].AMOUNT
        }

        let productWorkflow = false
        workflowFields.forEach(field => {
            if (production?.[field]) {
                blueprintData[field] = production[field]
                if (!productWorkflow && workflowOrderFields.includes(field)) {
                    productWorkflow = true
                }
            }
        })

        setIsWorkflowProductBased(productWorkflow)
        setData({
            ...blueprintData,
            STOCK_AMOUNT: production.STOCK_AMOUNT || blueprintData.STOCK_AMOUNT,
        })
    }, [blueprint, production])

    const setField = (field, value) => {
        setErrors({ ...initialBlueprint })
        setData({ ...data, [field]: value })

        if (workflowFields.includes(field)) {
            setChangedFields(changedFields => ({ ...changedFields, [field]: value }))
        }
    }

    const isValidInput = () => {
        let valid = true

        setErrors({ ...initialBlueprint })
        const newErrors = initialBlueprint

        if (!data?.FILE) {
            valid = false
            newErrors.FILE = t('blueprint.file_required')
        }

        setErrors({ ...newErrors })

        return valid
    }

    const handleUpdate = async (fieldsToUpdate) => {
        if (!isValidInput()) return

        setLoading(true)

        try {
            let fileName = undefined
            if (data?.FILE?.name) fileName = await handleUpload()
            const {
                blueprintData,
                prodData,
            } = validateFields(fieldsToUpdate || data, fileName)

            if (isBuffer) {
                updateBuffer({
                    variables: {
                        id: parseInt(production.ID, 10),
                        data: prodData,
                    },
                })
            } else {
                updateProd({
                    variables: {
                        id: parseInt(production.ID, 10),
                        data: prodData,
                    },
                })
            }

            const res = await updateBlueprint({
                variables: {
                    id: parseInt(blueprint.ID, 10),
                    data: blueprintData,
                },
                refetchQueries: [GetBlueprintLog],
            })

            if (res?.data?.updateBlueprint !== 'SUCCESS') return

            if (onSuccess) onSuccess()
        } catch (err) {
            console.log('ERROR updating blueprint', err)
        } finally {
            setLoading(false)
        }
    }

    const handleSave = () => {
        if (Object.keys(changedFields).length > 0) {
            if (!isValidInput()) return
            setShowModal(true)
        } else {
            handleUpdate()
        }
    }

    const handleModalClose = () => {
        setShowModal(false)
    }

    const handleRefresh = async () => {
        await refetchBlueprint()
        await prodFetch()
    }

    const handleModalChoice = async (choice, fieldType) => {
        if (!changedFields) return

        let fieldsToUpdate = {}

        switch (fieldType) {
            case 'workflow':
                fieldsToUpdate = workflowOrderFields.reduce((obj, key) => {
                    obj[key] = data[key] || null
                    return obj
                }, {})
                break
            default:
                fieldsToUpdate = {
                    [fieldType]: changedFields[fieldType],
                }
                break
        }
        setLoadingField(fieldType)

        if (choice === 'updateProductionCode') {
            handleUpdate(fieldsToUpdate)

            const prodData = Object.keys(fieldsToUpdate).reduce((acc, key) => {
                acc[key] = null
                return acc
            }, {})

            if (isBuffer) {
                await updateBuffer({
                    variables: {
                        id: parseInt(production.ID, 10),
                        data: prodData,
                    },
                })
            } else {
                await updateProd({
                    variables: {
                        id: parseInt(production.ID, 10),
                        data: prodData,
                    },
                })
            }
        } else if (choice === 'updateProduct') {
            const prodData = { ...fieldsToUpdate }

            for (const [key, value] of Object.entries(prodData)) {
                switch (key) {
                    case 'PCS_PER_HIT':
                        prodData[key] = parseFloat(value)
                        break
                    case 'PCS_PER_STOCK':
                        prodData[key] = parseFloat(value)
                        break
                    default:
                        break
                }
            }

            if (isBuffer) {
                await updateBuffer({
                    variables: {
                        id: parseInt(production.ID, 10),
                        data: prodData,
                    },
                })
            } else {
                await updateProd({
                    variables: {
                        id: parseInt(production.ID, 10),
                        data: prodData,
                    },
                })
            }

            let fileName = undefined
            if (data?.FILE?.name) fileName = await handleUpload()
            const {
                blueprintData,
                productionData,
            } = validateFields(prodData, fileName)

            await createBlueprintLog({
                variables: {
                    id: parseInt(blueprint.ID, 10),
                    production: production.ID && production.ID,
                    isBuffer: isBuffer,
                    data: blueprintData,
                },
                refetchQueries: [GetBlueprintLog],
            })
        }
        await handleRefresh()
        const updatedChangedFields = { ...changedFields }
        Object.keys(fieldsToUpdate).forEach(key => {
            delete updatedChangedFields[key]
        })

        if (Object.keys(updatedChangedFields).length === 0) {
            handleModalClose()
            setChangedFields({})
        } else {
            setChangedFields(updatedChangedFields)
        }
        setLoadingField(null)
    }

    const handleUpload = async () => {
        try {
            const res = await upload({
                variables: {
                    file: data.FILE,
                    orderRowId: parseInt(orderRowId),
                },
            })

            return res?.data?.singleUpload?.filename
        } catch (err) {
            console.log('handleUpload', err)
        }
    }

    const handlePlanning = async () => {
        try {
            const res = await sendProductionToPlanning({
                variables: {
                    blueprintId: parseInt(blueprint.ID, 10),
                    productionId: production.ID && production.ID,
                    isBuffer: isBuffer,
                    productBasedOrder: isWorkflowProductBased
                },
            })
            if (res?.data?.sendProductionToPlanning.status === 200) {
                dispatch({
                    type: 'ADD',
                    payload: {
                        type: 'success',
                        content: t('Toode planeerimisse saadetud')
                    },
                })
            } else {
                dispatch({
                    type: 'ADD',
                    payload: {
                        type: 'error',
                        content: t('Toote lisamine planeerimisse ebaõnnestus')
                    },
                })
            }
            setShowPlanningConfirmationModal(false)
        } catch (err) {
            console.log('handlePlanning', err)
        }
    }

    return (
        <div className='blueprint-new'>
            <div className='upload-file'>
                {
                    !data?.FILE ?
                        <></>
                        :
                        <div className='blueprint-file'>
                            {
                                data?.FILE?.name ?
                                    <div className='upload-file--filename'>{data?.FILE?.name}</div>
                                    :
                                    <a href={`${FileUrl}${data.FILE}`} target='_blank'>{`${t('blueprint.view_blueprint')}`}</a>
                            }
                        </div>
                }
                <div className='upload-file--type'>
                    <FileInput
                        label={t('Vali joonis arvutist')}
                        onChange={(e) => setField('FILE', e.target.files[0])}
                        allowedTypes={'.pdf'}
                    />
                </div>
                <BoxNotification message={errors.FILE} type={'error'} />
            </div>
            <h4>{t('blueprint.machine_order')}{ isWorkflowProductBased && ' - Tootepõhine'}</h4>
            <BoxWorkflow
                onChange={setField}
                items={[
                    {
                        field: 'SLITTER',
                        value: data.SLITTER,
                    },
                    {
                        field: 'TIGEL',
                        value: data.TIGEL,
                    },
                    {
                        field: 'SRE',
                        value: data.SRE,
                    },
                    {
                        field: 'INLINE',
                        value: data.INLINE,
                    },
                    {
                        field: 'SLOTTER',
                        value: data.SLOTTER,
                    },
                    {
                        field: 'KLIIM',
                        value: data.KLIIM,
                    },
                    {
                        field: 'ALIIM',
                        value: data.ALIIM,
                    },
                    {
                        field: '_3LIIM',
                        value: data._3LIIM,
                    },
                    {
                        field: 'LAMINAATOR',
                        value: data.LAMINAATOR,
                    },
                    {
                        field: 'TOOTLEMINE',
                        value: data.TOOTLEMINE,
                    },
                    {
                        field: 'EKSTSEN',
                        value: data.EKSTSEN,
                    },
                    {
                        field: 'AFD',
                        value: data.AFD,
                    },
                    {
                        field: 'FPR',
                        value: data.FPR,
                    },
                    {
                        field: 'PREMAID',
                        value: data.PREMAID,
                    },
                    {
                        field: 'TITAN',
                        value: data.TITAN,
                    },
                    {
                        field: 'VERONA',
                        value: data.VERONA,
                    },
                ]}
            />
            <h4>{t('blueprint.colors')}</h4>
            <BoxColorFields
                pantone1={data.PANTONE1}
                pantone2={data.PANTONE2}
                pantone3={data.PANTONE3}
                pantone4={data.PANTONE4}
                onColorChange={setField}
                pantoneOnly
            />
            <Directions
                onChange={setField}
                data={{
                    FLUTE: data.FLUTE,
                    PRINTING: data.PRINTING,
                    FEEDING: data.FEEDING,
                }}
            />

            <div className='blueprint-fields'>
                <Input
                    label={'Terast terani'}
                    value={data.TT}
                    onChange={(e) => setField('TT', e.target.value)}
                />
                <Input
                    label={`Tükki löögist ${production.PCS_PER_HIT ? '- Tootepõhine' : ''}`}
                    value={data.PCS_PER_HIT}
                    onChange={(e) => setField('PCS_PER_HIT', e.target.value)}
                    type={'number'}
                />
                <div className='field-row'>
                    <Input
                        label={'Offset mõõt'}
                        value={data.OFFSET_MOOT}
                        onChange={(e) => setField('OFFSET_MOOT', e.target.value)}
                    />
                    <Input
                        label={'Offset kogus'}
                        value={data.OFFSET_AMOUNT}
                        onChange={(e) => setField('OFFSET_AMOUNT', e.target.value)}
                        type={'number'}
                    />
                </div>
                <Input
                    label={'Nuudid'}
                    value={data.NUUT}
                    onChange={(e) => setField('NUUT', e.target.value)}
                />
                <div className='field-row'>
                    <Input
                        label={'Klishee number'}
                        value={data.KLISHEE}
                        onChange={(e) => setField('KLISHEE', e.target.value)}
                    />
                    <Input
                        label={'Klishee asukoht'}
                        value={data.KLISHEE_LOCATION}
                        onChange={(e) => setField('KLISHEE_LOCATION', e.target.value)}
                    />
                </div>
                <div className='field-row'>
                    <Input
                        label={'Matriitsi number'}
                        value={data.MATRIITS}
                        onChange={(e) => setField('MATRIITS', e.target.value)}
                    />
                    <Input
                        label={'Matriitsi asukoht'}
                        value={data.MATRIITS_LOCATION}
                        onChange={(e) => setField('MATRIITS_LOCATION', e.target.value)}
                    />
                </div>
            </div>
            <div className='field-row justify-content--flex-start blueprint-constructors'>
                <SelectInput
                    options={constructors}
                    value={constructors?.find(i => i.ID === data.CONSTRUCTOR)}
                    label={'Stantsi konstruktor'}
                    onChange={(option) => setField('CONSTRUCTOR', option?.ID)}
                    getOptionLabel={(option) => option?.RNAME}
                    getOptionValue={(option) => option?.ID}
                />
                <SelectInput
                    options={constructors}
                    value={constructors?.find(i => i.ID === data.DESIGNER)}
                    label={'Klishee disainer'}
                    onChange={(option) => setField('DESIGNER', option?.ID)}
                    getOptionLabel={(option) => option?.RNAME}
                    getOptionValue={(option) => option?.ID}
                />
            </div>
            <div className='blueprint-fields'>
                <Input
                    label={'Pakkeviis'}
                    value={data.PACKAGING_METHOD}
                    onChange={(e) => setField('PACKAGING_METHOD', e.target.value)}
                />
                <Input
                    label={'Kogus alusel'}
                    value={data.AMOUNT_PALLET}
                    onChange={(e) => setField('AMOUNT_PALLET', e.target.value)}
                    type={'number'}
                />
                <Input
                    label={'Kohtade arv'}
                    value={data.SLOTS}
                    onChange={(e) => setField('SLOTS', e.target.value)}
                    type={'number'}
                />
                <Input
                    label={`Tooriku mõõt ${production.STOCK_MOOT ? '- Tootepõhine' : ''}`}
                    value={data.STOCK_MOOT}
                    onChange={(e) => setField('STOCK_MOOT', e.target.value)}
                />
                <Input
                    label={'Tooriku kogus'}
                    value={data.STOCK_AMOUNT}
                    onChange={(e) => setField('STOCK_AMOUNT', e.target.value)}
                />
                <Input
                    label={`Tükki toorikust ${production.PCS_PER_STOCK ? '- Tootepõhine' : ''}`}
                    value={data.PCS_PER_STOCK}
                    onChange={(e) => setField('PCS_PER_STOCK', e.target.value)}
                    type={'number'}
                />
                <Textarea
                    label={'Kommentaar'}
                    value={data.COMMENT}
                    onChange={(e) => setField('COMMENT', e.target.value)}
                />
                <Input
                    label={'Aluse tüüp'}
                    value={data.PALLET_TYPE}
                    onChange={(e) => setField('PALLET_TYPE', e.target.value)}
                />
                <Input
                    label={'Paigutus alusel'}
                    value={data.PALLET_LAYOUT}
                    onChange={(e) => setField('PALLET_LAYOUT', e.target.value)}
                />
            </div>
            <BlueprintLog
                productionCode={blueprint?.PRODUCTION_CODE}
                productionId={production?.ID}
            />
            <div className='modal-actions justify-content--space-between'>
                <BlueprintModal
                    orderRowId={orderRowId}
                    productionCode={blueprint?.PRODUCTION_CODE}
                    production={production}
                />
                <div className="modal-actions-buttons">
                    <Button
                        label={"Planeerimisse"}
                        onClick={() => setShowPlanningConfirmationModal(true)}
                    />
                    <Button
                        label={'Salvesta'}
                        onClick={handleSave}
                        disabled={loading}
                        loading={loading}
                    />
                </div>
            </div>
            <AdditionalModal
            className="blueprint-modal-additional"
                show={showModal}
                close={handleModalClose}
                title="Kinnitust vajavad muudatused"
            >
                <div className='blueprint-new'>
                        {Object.keys(changedFields).some(field =>
                        workflowOrderFields.includes(field)) && (
                            <div
                                className='blueprint-item'
                            >
                                <h4>{"Kas salvestan MASINATE JÄRJEKORRA TOOTEKOODILE või ANTUD REALE?"}</h4>
                                <div>
                                    <Button
                                        onClick={() => handleModalChoice('updateProductionCode', 'workflow')}
                                        label={"Tootekoodile"}
                                        loading={loadingField === 'workflow'}
                                    />
                                    <Button
                                        onClick={() => handleModalChoice('updateProduct', 'workflow')}
                                        label={"Reale"}
                                        loading={loadingField === 'workflow'}
                                    />
                                </div>
                            </div>
                        )}

                        {['PCS_PER_HIT', 'STOCK_MOOT', 'PCS_PER_STOCK'].map(field =>
                        changedFields[field] && (
                            <div
                                className='blueprint-item'
                                key={field}
                            >
                                <h4>Kas salvestan {field === 'PCS_PER_HIT' ? 'TÜKKI LÖÖGIST' : field === 'STOCK_MOOT' ? 'TOORIKU MÕÕTU' : 'TÜKKI TOORIKUST'} TOOTEKOODILE või ANTUD REALE?</h4>
                                <div>
                                    <Button
                                        onClick={() => handleModalChoice('updateProductionCode', field)}
                                        label={"Tootekoodile"}
                                        loading={loadingField === field}
                                    />
                                    <Button
                                        onClick={() => handleModalChoice('updateProduct', field)}
                                        label={"Reale"}
                                        loading={loadingField === field}
                                    />
                                </div>
                            </div>
                            )
                        )}
                </div>
            </AdditionalModal>
            <ConfirmModal
                show={showPlanningConfirmationModal}
                close={() => {
                    setShowPlanningConfirmationModal(false)
                }}
                title={`Kas oled kindel, et soovid toodet planeerimisse saata? Olemasolevad toote plaanid muutuvad`}
                confirmLabel={'Jah'}
                cancelLabel={'Ei'}
                onConfirm={() => handlePlanning()}
            >
            </ConfirmModal>
        </div>
    )
}

export default EditBlueprint