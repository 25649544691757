import React, { useEffect, useState } from 'react'
import Modal from './Modal'
import Button from './Button'
import PdfViewer from './PdfViewer'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { GetBlueprint, GetOrderRow, SearchMaterial } from '../graphql/queries'
import { FileUrl, workflowFields } from '../util/const'
import TechnicalCardDocument from '../documents/technical-card-document'
import { pdf } from '@react-pdf/renderer'

const BlueprintModal = ({
    orderRowId,
    productionCode,
    production,
}) => {

    const { t } = useTranslation()
    const [showTechModal, setShowTechModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState()

    const { refetch: searchMaterial } = useQuery(SearchMaterial, {
        skip: true,
        variables: {
            searchInput: '',
        },
        fetchPolicy: 'no-cache',
    })

    const { refetch } = useQuery(GetOrderRow, {
        skip: true,
        fetchPolicy: 'no-cache',
        variables: {
            id: parseInt(orderRowId, 10),
        },
    })

    const { refetch: refetchBlueprint } = useQuery(GetBlueprint, {
        skip: true,
        fetchPolicy: 'no-cache',
        variables: {
            productionCode,
        },
    })

    useEffect(() => {
        fetchData()
    }, [orderRowId, productionCode])

    const fetchData = async () => {
        const blueprintRes = await refetchBlueprint()

        let materialRes = undefined

        if (production?.materialOrders?.length) {
            materialRes = await searchMaterial({
                searchInput: production.materialOrders[0].MARK
            })
        }

        let image
        
        if (blueprintRes?.data?.getBlueprint?.FILE) {
            const file = blueprintRes.data.getBlueprint.FILE
            const fileName = file.split('.').shift()
            image = `${FileUrl}compressed/${fileName}.png`
        }

        const blueprintData = { ...blueprintRes?.data?.getBlueprint }
        workflowFields.forEach(field => {
            if (production?.[field]) {
                blueprintData[field] = production[field]
            }
        })

        const result = {
            material: materialRes?.data?.searchMaterial?.length ? materialRes?.data?.searchMaterial[0] : undefined,
            image,
            blueprint: {...blueprintData},
            production,
            materialOrder: production?.materialOrders?.length ? production.materialOrders[0] : undefined
        }

        setData(result)

        return result
    }

    const handleOpenModal = async () => {
        setLoading(true)

        const dataRes = await fetchData()

        const techPdf = pdf(<TechnicalCardDocument data={dataRes} />)
        techPdf.updateContainer(<TechnicalCardDocument data={dataRes} />)

        setShowTechModal(true)
        setLoading(false)
    }

    return (
        <div className='blueprint'>
            <Button
                label={t('Ava tehnoloogiline kaart')}
                onClick={handleOpenModal}
                disabled={loading || !production}
                loading={loading}
            />
            <Modal
                show={showTechModal}
                className={'blueprint-modal-pdf'}
                close={() => setShowTechModal(false)}
            >
                <PdfViewer document={'technical-card'} data={data} />
            </Modal>
        </div>
    )
}

export default BlueprintModal