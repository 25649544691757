import React, { useEffect, useState } from 'react'
import Modal from './Modal'
import Button from './Button'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { GetBlueprint, GetBlueprints, GetConstructors } from '../graphql/queries'

import AddBlueprint from './AddBlueprint'
import EditBlueprint from './EditBlueprint'
import { useNotification } from '../providers/Notification'

const initialBlueprint = {
    ID: '',
    COMMENT: '',
    CREATOR: '',
    CREATED: '',
    PCS_PER_HIT: '',
    PCS_PER_STOCK: '',
    SLITTER: '',
    FLEXO: '',
    TIGEL: '',
    SRE: '',
    INLINE: '',
    SLOTTER: '',
    KLIIM: '',
    ALIIM: '',
    _3LIIM: '',
    LAMINAATOR: '',
    STOCK_AMOUNT: '',
    STOCK_MOOT: '',
    OFFSET_AMOUNT: '',
    OFFSET_MOOT: '',
    TOOTLEMINE: '',
    EKSTSEN: '',
    PREMAID: '',
    PRODUCTION_CODE: '',
    VERONA: '',
    TITAN: '',
    AFD: '',
    FPR: '',
    KLISHEE: '',
    MATRIITS: '',
    TT: '',
    PANTONE1: '',
    PANTONE2: '',
    PANTONE3: '',
    PANTONE4: '',
    PACKAGING_METHOD: '',
    AMOUNT_PALLET: '',
    SLOTS: '',
    FILE: null,
    PALLET_TYPE: '',
    PALLET_LAYOUT: '',
}

const TechnicalCard = ({
    orderRowId,
    productionCode,
    production,
    isBuffer,
    prodFetch
}) => {

    const { t } = useTranslation()
    const { dispatch } = useNotification()
    const [showTechModal, setShowTechModal] = useState(false)
    const [blueprintData, setBlueprintData] = useState(initialBlueprint)
    const [blueprintsData, setBlueprintsData] = useState([])

    const { refetch: refetchBlueprint } = useQuery(GetBlueprint, {
        skip: true,
        fetchPolicy: 'no-cache',
        variables: {
            productionCode,
        },
    })

    const { refetch: refetchBlueprints } = useQuery(GetBlueprints, {
        fetchPolicy: 'no-cache',
    })

    const { data: constructorRes } = useQuery(GetConstructors, {
        fetchPolicy: 'no-cache',
    })

    useEffect(() => {
        fetchData()
    }, [productionCode, showTechModal])

    const fetchData = async () => {
        const blueprintRes = await refetchBlueprint()
        const blueprintsRes = await refetchBlueprints()    

        if (blueprintRes?.data?.getBlueprint) {
            setBlueprintData({...blueprintData, ...blueprintRes.data.getBlueprint})
        }
        if (blueprintsRes?.data?.getBlueprints) {
            setBlueprintsData([...blueprintsRes.data.getBlueprints])
        }
    }

    const handleOpenModal = async () => {
        setShowTechModal(true)
    }

    const handleBlueprintCreated = () => {
        setShowTechModal(false)
        setBlueprintData({...initialBlueprint})

        dispatch({
            type: 'ADD',
            payload: {
                content: t('blueprint.created'),
                type: 'success',
            },
        })
    }

    return (
        <div className='blueprint'>
            <Button
                label={t('Tehnoloogiline kaart')}
                onClick={handleOpenModal}
            />
            <Modal
                show={showTechModal}
                className={'blueprint-modal'}
                close={() => setShowTechModal(false)}
                title={!blueprintData?.ID ? t('blueprint.new_title') : t('blueprint.edit_title')}
            >
                {
                    !blueprintData?.ID ?
                        <AddBlueprint
                            orderRowId={orderRowId}
                            productionCode={productionCode}
                            production={production}
                            onSuccess={handleBlueprintCreated}
                            constructors={constructorRes?.getConstructors}
                            isBuffer={isBuffer}
                            blueprints={blueprintsData}
                        />
                        :
                        <EditBlueprint
                            orderRowId={orderRowId}
                            blueprint={blueprintData}
                            onSuccess={handleBlueprintCreated}
                            production={production}
                            constructors={constructorRes?.getConstructors}
                            isBuffer={isBuffer}
                            prodFetch={prodFetch}
                            refetchBlueprint={fetchData}
                        />
                }
            </Modal>
        </div>
    )
}

export default TechnicalCard